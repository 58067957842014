<template>
  <div class="applyFlexCenterWithHeight tw-px-5">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(resetPassword)" id="handleReset">
        <div class="tw-mb-9">
          <h3 class="tw-font-black tw-text-lg">Reset Password</h3>
          <small>Enter your email address to reset your password.</small>
        </div>
        <p>Email Address</p>
        <ValidationProvider
            name="e-mail"
            rules="required|email"
            v-slot="{ errors }"
        >
          <v-text-field
              placeholder="Enter your email address"
              v-model="email"
              solo
              hide-details
              @input="value => { email = value.toLowerCase();}"
              class="email register-input"
              id="email"
          ></v-text-field>
          <span class="err">{{ errors[0] }}</span><br>
        </ValidationProvider>
        <span style="font-size: 14px; padding: 16px 0" v-if="email.includes(('@'))">A reset link will be sent to <span
            style="color:  #008EEF; font-weight: bolder">{{
            email
          }}</span><br> Please don’t share this with anyone.</span>
        <BaseButton :loading="loading" depressed id="passwordResetBtn" class="tw-mt-10 tw-mb-4"
                    button-text="Send reset link to email" width="361"/>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import BaseButton from "../../../UIComponents/BaseButton";
import {resetPassword} from "../../../../services/api/AuthApiService";

export default {
  name: "ResetPassword",
  components: {BaseButton},
  data() {
    return {
      email: '',
      loading: false
    }
  },
  computed: {},
  methods: {
    resetPassword() {
      this.loading = true
      const data = {
        login: this.email
      };

      resetPassword(data).then(
          response => {
            console.log(response);
            this.$displaySnackbar({
              message: response.data.detail,
              success: true
            })
            this.loading = false
            this.email = '';
            this.$router.push('/login')
          },
          error => {
            console.log(error.response.data.detail);
            this.$displaySnackbar({
              message: error.response.data.detail,
              success: false
            })
            this.loading = false
          }
      )


    }
  }
}
</script>

<style scoped>

</style>
